<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑维保模板':'新增维保模板'"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('device.labelWidth','80px')">
      <el-form-item label="模板名称" prop="name">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="地区" prop="cityCode" :rules="$rule.notNull">
        <vm-district-city-select
          :province-code.sync="model.provinceCode"
          :city-code.sync="model.cityCode">
        </vm-district-city-select>
      </el-form-item>
      <el-form-item label="保养类型" prop="type" :rules="$rule.notNull">
        <vm-dict-select v-model="model.type" type="wbType"></vm-dict-select>
      </el-form-item>
      <el-form-item label="电梯类型" prop="elevatorType" :rules="$rule.notNull">
        <vm-dict-select v-model="model.elevatorType" type="elevatorWbType"></vm-dict-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>


  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          name: "",
          provinceCode: "",
          cityCode: "",
          type: "",
          elevatorType: "",
          copyFromId: 0,
        },
      };
    },
    methods: {
      open(id, copyFromId) {
        this.model.id = id;
        this.model.copyFromId = copyFromId;
        this.dialogVisible = true;
        this.getData(id);
        this.getData(copyFromId);
      },
      getData(id) {
        if (id) {
          this.contentLoading = true;
          this.$http.get(`wbTemplates/${id}`).then(data => {
            this.contentLoading = false;
            this.$assign(this.model, data);
          });
        }
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            let request = this.model.copyFromId ? this.$http.post("wbTemplates/copy/" + this.model.copyFromId, this.model) : this.$http.save("wbTemplates", this.model);
            request
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
